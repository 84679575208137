import React from 'react';
import App from 'next/app';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import BaseLayout from '../components/BaseLayout';

import { setupSession } from '../utils/session';

import '../sass/base.scss';
import '../public/styles/slick.min.css';

class NeonPantheonApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };

    dayjs.extend(relativeTime);
    dayjs.extend(utc);
  }

  render = () => {
    const { Component, pageProps } = this.props;
    return (
      <BaseLayout>
        <Component {...pageProps} />
      </BaseLayout>
    );
  }
}

NeonPantheonApp.getInitialProps = async (context) => {
  if (typeof window !== 'undefined') {
    return {};
  }
  const { req, res } = context.ctx;
  await setupSession(req, res);

  return {
    accessToken: req.session.get('access_token'),
    refreshToken: req.session.get('refresh_token'),
  };
};

export default NeonPantheonApp;
