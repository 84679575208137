import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { eventOn, eventOff } from '../../utils/global';

import styles from '../../sass/components/common/Snackbar.module.scss';

const SHOW_TIME = 3000;

const mapStoreToProps = (store) => ({
  localization: store.data.localization && store.data.localization.snackbar || {}
});

class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      text: null,
      type: null,
    };
    this.timeout = null;
  }

  componentDidMount = () => {
    eventOn('showSnackbar', this.showSnackbar);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
    eventOff('showSnackbar', this.showSnackbar);
  }

  showSnackbar = (e) => {
    clearTimeout(this.timeout);
    const { text, type = null } = e.detail[0];
    this.setState({show: true, text, type});
    this.timeout = setTimeout(this.onClose, SHOW_TIME);
  }

  onClose = () => {
    clearTimeout(this.timeout);
    this.setState({show: false});
  }

  render = () => {
    const { show, text, type } = this.state;
    const successMessage = this.props.localization.success;
    return (
      <div
        className={classnames(styles.snackbar, {
          [styles.show]: show,
          [styles[type]]: !!type,
        })}
      >
        <div className={styles.snackbarOverlay} />
        <div className={styles.snackbarContainer}>
          <div className={styles.snackbarText} dangerouslySetInnerHTML={{__html: text || successMessage}} />
          <div
            onClick={this.onClose}
            className={styles.snackbarClose}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(Snackbar);
