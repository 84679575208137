import axios from 'axios';

export const apiUrl = (apiMethod, scope) => {
  return `${process.env.NEXT_PUBLIC_BASE_URL}/api/${scope}/${apiMethod}`;
}

export const WSUrl = () => {
  if (typeof window === 'undefined') return;
  return process.env.NEXT_PUBLIC_BASE_URL.replace(/^http/, 'ws')
    + '/cable'
    + '?token=' + localStorage.getItem('neonpantheon-client:token');
}

export const getUrlParameter = (sParam, location) => {
  if (typeof window === 'undefined') return;
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
}

export const sendRequest = ({token, method, scope = 'v1', headers = {}, data, type = 'GET', silent = false, onProgress, responseType = 'json'}) => {
  const serverSide = typeof window === 'undefined';
  return new Promise((resolve, reject) => {
    if (!token && !serverSide) {
      token = localStorage.getItem('neonpantheon-client:token');
    }
    const requestType = type.toUpperCase();
    const handleError = (response) => {
      if (!serverSide) {
        if (response.status === 401) {
          if (!method.includes('logout')) {
            triggerEvent('unauthorized');
          }
        }
        if (response.status === 503) {
          triggerEvent('showSnackbar', [{text: 'Network error, please try again later.', type: 'error'}]);
        }
      }
    }
    if (!silent) {
      triggerEvent('addLoad');
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    axios({
      url: apiUrl(method, scope),
      method: requestType,
      data: requestType === 'GET' ? null : data,
      params: requestType === 'GET' ? data : null,
      headers: headers,
      responseType: responseType,
      onUploadProgress: e => {
        if (onProgress) {
          onProgress(e.loaded / e.total);
        }
      }
    })
      .then(response => {
        if (!silent && !serverSide) {
          triggerEvent('removeLoad');
        }
        if (response.status >= 200 && response.status < 400) {
          resolve(response.data);
        } else {
          handleError(response);
          reject(response.data);
        }
      })
      .catch(err => {
        if (!silent && !serverSide) {
          triggerEvent('removeLoad');
        }
        let error = '';
        if (err.response) {
          handleError(err.response);
          error = err.response.data;
        }
        reject(error)
      })
  });
}

export const triggerEvent = (name, data) => {
  if (typeof window === 'undefined') return;
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, {detail: data});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
}

export const triggerNativeEvent = (elem, name) => {
  if (typeof window === 'undefined') return;
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
}

export const triggerClick = (elem) => {
  if (typeof window === 'undefined') return;
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent('click',true,true,window,0,0,0,0,0,false,false,false,false,0,null);
  elem.dispatchEvent(event);
}

export const eventOn = (name, handler, parent) => {
  if (typeof window === 'undefined') return;
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
}

export const eventOff = (name, handler, parent) => {
  if (typeof window === 'undefined') return;
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
}

export const isIOS = () => {
  if (typeof window === 'undefined') return;
  return (
    (!window.MSStream && /iPad|iPhone|iPod/i.test(navigator.userAgent)) ||
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const formatedAmountString = (value, precision = 0, separator = ',') => {
  let num = Number(value);
  if (isNaN(num)) {
    num = 0;
  }
  const str = num.toFixed(precision);
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const redirectUnauthorized = (req, res) => {
  if (!req.session.get('token')) {
    res.setHeader('location', '/');
    res.statusCode = 302;
    res.end();
    return {
      props: {}
    }
  }
}
