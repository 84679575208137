import React from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import classnames from 'classnames';

import styles from '../sass/components/TheHeader.module.scss';

const NAV_LINKS = [
  {
    id: 1,
    route: '/',
    name: 'Home',
  },
  {
    id: 3,
    action: () => window.open('/files/Neon-Pantheon-White-Paper-v1.pdf', '_blank'),
    name: 'Whitepaper',
  },
  {
    id: 4,
    route: '/enshrine',
    name: 'My Deities',
  },
  {
    id: 5,
    action: () => window.open('https://discord.gg/neonpantheon', '_blank'),
    name: 'Discord',
    icon: 'discord',
  },
  {
    id: 6,
    action: () => window.open('https://twitter.com/NeonPantheonNFT', '_blank'),
    name: 'Twitter',
    icon: 'twitter',
  },
  {
    id: 7,
    action: () => window.open('https://opensea.io/collection/neonpantheon', '_blank'),
    name: 'OpenSea',
    icon: 'opensea',
  },
];

class TheHeader extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleMobileLinkClick = (item) => {
    if (item.action) {
      item.action.call(this);
    } else {
      this.props.router.push(item.route);
    }
  }

  renderMenuItem = (item) => {
    return (
      <div className={styles.menuItem} key={item.id}>
        <div className={styles.menuLink} onClick={() => this.handleMobileLinkClick(item)}>
          {item.name}
          {item.icon ? <span className={styles[item.icon]} /> : null }
        </div>
      </div>
    );
  }

  handleMyDeitiesClick = () => {
    this.props.router.push('/enshrine');
  }

  openWhitepaper = () => {
    window.open('/files/Neon-Pantheon-White-Paper-v1.pdf', '_blank');
  }

  goHome = () => {
    this.props.router.push('/');
  }

  render = () => {
    return (
      <div className={classnames(styles.header, {[styles.sticky]: this.props.isSticky, [styles.navOpen]: this.props.navOpen})}>
        <div className={styles.mobileMenu}>
          {NAV_LINKS.map(item => this.renderMenuItem(item))}
        </div>
        <div className={styles.navbar}>
          <div className='container'>
            <div className={styles.flex}>
              <div className={styles.logo} onClick={this.goHome} />
              <div className={styles.navToggle} onClick={this.props.onNavToggle} />
              <div className={styles.contacts}>
                <span
                  className={styles.linkBtn}
                  onClick={this.openWhitepaper}
                >
                  Whitepaper
                </span>
                <span
                  className={styles.linkBtn}
                  onClick={this.handleMyDeitiesClick}
                >
                  My Deities
                </span>
                <span className={styles.opensea} onClick={() => window.open('https://opensea.io/collection/neonpantheon', '_blank')} />
                <span className={styles.discord} onClick={() => window.open('https://discord.gg/neonpantheon', '_blank')} />
                <span className={styles.twitter} onClick={() => window.open('https://twitter.com/NeonPantheonNFT', '_blank')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(TheHeader);
