import React from 'react';
import { withRouter } from 'next/router';

import styles from '../sass/components/TheFooter.module.scss';

class TheFooter extends React.PureComponent {

  render = () => {
    return (
      <div className={styles.footer}>
        <div className='container'>
          <div className={styles.flex}>
            <div className={styles.logo} />
            <div className={styles.copyrights}>©2022 Neon Pantheon. All rights reserved.</div>
            <div className={styles.contacts}>
              <span className={styles.opensea} onClick={() => window.open('https://opensea.io/collection/neonpantheon', '_blank')} />
              <span className={styles.discord} onClick={() => window.open('https://discord.gg/neonpantheon', '_blank')} />
              <span className={styles.twitter} onClick={() => window.open('https://twitter.com/NeonPantheonNFT', '_blank')} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(TheFooter);
