import React from 'react';
import { Provider } from 'react-redux';
import { withRouter } from 'next/router';
import classnames from 'classnames';

import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import LoadIndicator from '../components/common/LoadIndicator';
import Snackbar from '../components/common/Snackbar';

import Storage from '../utils/Storage';
import { eventOn } from '../utils/global';

import styles from '../sass/components/BaseLayout.module.scss';

class BaseLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
      headerIsSticky: false,
      navOpen: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);

      eventOn('resize', (e) => {
        Storage.setSetup('is_mobile', window.innerWidth <= 768);
      }, window);

      Storage.setSetup('is_mobile', window.innerWidth <= 768);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = document.documentElement.scrollTop;
    const isSticky = this.state.headerIsSticky;
    if (scrollPosition > 25 && !isSticky) {
      this.setState({ headerIsSticky: true });
    } else if (scrollPosition < 25 && isSticky) {
      this.setState({ headerIsSticky: false });
    }
  }

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  toggleNav = () => {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen
    }));
  }

  render = () => {
    const hideNav = this.props.router.pathname === '/knowledge_test/[id]';
    return (
      <Provider store={Storage.getStore()}>
        <div className={classnames(styles.baseLayout, {[styles.sticky]: this.state.headerIsSticky, [styles.navOpen]: this.state.navOpen})}>
          <LoadIndicator show={this.state.showLoadIndicator} />
          <Snackbar />
          { hideNav ? null : <TheHeader className={styles.header} isSticky={this.state.headerIsSticky} navOpen={this.state.navOpen} onNavToggle={this.toggleNav} /> }
          { this.props.children }
          <TheFooter />
        </div>
      </Provider>
    );
  }
}

export default withRouter(BaseLayout);
