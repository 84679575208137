import { applySession } from 'next-iron-session';

export const sessionOptions = {
  password: process.env.NEXT_SESSION_PASSWORD,
  // if your localhost is served on http:// then disable the secure flag
  ttl: 60 * 60 * 672, // 672hr => a month
  cookieName: 'neon-pantheon-coockie',
  cookieOptions: {
    secure: process.env.NODE_ENV === "production",
  },
}

export const setupSession = async (req, res) => {
  await applySession(req, res, sessionOptions);
}
